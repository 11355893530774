export default {
	GET_CURRENT: () => "/crew/current",
	GET_MEMBERS: (id) => `/crew/${id}/members`,
	GET_INVITATION: (invitationId) => `/crew/invitation?id=${invitationId}`,
	ACCEPT_INVITATION: () => "/crew/invitation/accept",
	CREATE: () => "/crew",
	UPDATE_NAME: (id) => `/crew/${id}/name`,
	UPDATE_MEMBER_ROLE: (id) => `/crew/${id}/member/role`,
	RENEW_INVITATION: (id) => `/crew/${id}/invitation/renew`,
	DELETE: (id) => `/crew/${id}`,
};
