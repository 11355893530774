import { getBuff, getSkill } from "@/pages/Healbot/utils/constants";
import { makeAutoObservable } from "mobx";

// eslint-disable-next-line
let _rootStore;

const generateGroupMember = ({ name }) => {
	return {
		type: "player",
		id: name,
		name: name,
		level: 1,
		stats: {
			health: 1000,
			maxHealth: 1000,
			mana: 400,
			maxMana: 500,
			manaRegen: 1,
		},
		attributes: {
			strength: 1,
			agility: 1,
			intelligence: 1,
			faith: 1,
		},
		buffs: [],
		talents: {},
		skills: [],
	};
};

export default class HealbotStore {
	constructor(rootStore) {
		// construct(this, defaultProperties);

		this.selectedTarget = null;

		this.casting = {
			skill: null,
			castTime: 0,
			remainingCastTime: 0,
			target: null,
		};

		this.cooldowns = {
			// "HOLY_TOUCH": 0,
		};
		this.keybinds = {
			1: "HOLY_TOUCH",
			2: "RENEW",
			3: "HOLY_HAND",
			4: "RELIEF",
		};
		this.group = [
			{
				type: "player",
				id: "self",
				name: "self",
				level: 1,
				stats: {
					health: 800,
					maxHealth: 1000,
					mana: 400,
					maxMana: 500,
					manaRegen: 1,
				},
				attributes: {
					strength: 1,
					agility: 1,
					intelligence: 5,
					faith: 10,
				},
				buffs: [
					// {id, duration, stacks}
				],
				talents: {},
				skills: [
					{
						id: "HOLY_TOUCH",
						level: 1,
						keybind: 1,
					},
				],
			},
			generateGroupMember({ name: "party1" }),
			generateGroupMember({ name: "party2" }),
			generateGroupMember({ name: "party3" }),
			generateGroupMember({ name: "party4" }),
		];
		this.enemies = [
			{
				type: "enemy",
				name: "Goblin",
				level: 1,
				health: 1000,
				maxHealth: 1000,
				manaRegen: 1,
				strength: 5,
				agility: 5,
				intelligence: 1,
				faith: 0,
			},
		];
		_rootStore = rootStore;

		makeAutoObservable(this);
		// persist(this, "healbotStore");
	}

	getPlayerById(id) {
		if (id === "#random") {
			return this.getRandomPlayer();
		}

		return this.group.find((_player) => _player.id === id);
	}

	getRandomPlayer() {
		const alivePlayers = this.group.filter((_player) => _player.stats.health > 0);
		const randomIndex = Math.floor(Math.random() * alivePlayers.length);

		return this.group[randomIndex];
	}

	selectTarget(id) {
		const player = this.getPlayerById(id);
		if (!player) return;

		this.selectedTarget = player;
	}

	/**
	 * @param {string} targetId - The ID of the player.
	 * @param {string} [buffId] - The ID of the buff to retrieve. If not provided, all buffs of the player will be returned.
	 * @return {{} | null} The buff object if found, or null if not found.
	 */
	getBuffFromPlayer(targetId, buffId) {
		const player = this.getPlayerById(targetId);
		if (!player) return null;

		if (!buffId) {
			return player.buffs.map((_x) => {
				return {
					..._x,
					buffData: getBuff(_x.id),
				};
			});
		}

		const playerBuff = player.buffs.find((_buff) => _buff.id === buffId);
		if (!playerBuff) return null;

		const buffData = getBuff(buffId);
		if (!buffData) return null;

		return {
			...playerBuff,
			buffData,
		};
	}

	useKeybind(keybind) {
		const skillId = this.keybinds[keybind];
		if (!skillId) return;

		this.castSkill(skillId);
	}

	castSkill(skillId) {
		if (this.casting.skill?.id) return;

		const target = this.selectedTarget;
		if (!target) return;

		const skill = getSkill(skillId);

		this.casting.skill = skill;
		this.casting.castTime = skill.castTime;
		this.casting.remainingCastTime = skill.castTime;
		this.casting.target = target;

		if (skill.applyBuffs?.length) {
			this.applySkillBuffs({
				targetId: target.id,
				skillId,
			});
		}
	}

	useSkill(skillId) {
		const targetId = this.casting.target?.id;
		if (!targetId) return console.warn("[useSkill] targetId target");

		const skill = getSkill(skillId);

		let fncKey = null;

		if (skill.type === "heal") {
			fncKey = "healPlayer";
		} else if (skill.type === "damage") {
			fncKey = "damagePlayer";
		}

		this[fncKey]({
			id: targetId,
			amount: skill.basePower,
		});

		this.casting.skill = null;
		this.casting.castTime = 0;
		this.casting.remainingCastTime = 0;
		this.casting.target = null;
	}

	healPlayer({ id, amount }) {
		const player = this.getPlayerById(id);
		if (!player) return console.warn("[healPlayer] player not found");

		const newHealth = Math.min(player.stats.maxHealth, player.stats.health + amount);
		player.stats.health = newHealth;
	}

	damagePlayer({ id, amount }) {
		const player = this.getPlayerById(id);
		if (!player) return;

		const newHealth = Math.max(0, player.stats.health - amount);
		player.stats.health = newHealth;

		if (newHealth <= 0) {
			player.isDead = true;
		}

		// Check events on present buffs
		const allBuffs = this.getBuffFromPlayer(id);

		for (const _buff of allBuffs) {
			if (_buff?.buffData?.onReceivedDamage) {
				_buff.buffData.onReceivedDamage({
					target: player,
					amount,
				});
			}
		}
	}

	removeBuff({ targetId, buffId }) {
		const target = this.getPlayerById(targetId);
		if (!target) return console.warn("[removeBuff] target not found");

		const found = target.buffs.find((_buff) => _buff.id === buffId);
		if (!found) {
			console.warn("[removeBuff] buff not found");
			return null;
		}

		const idx = target.buffs.findIndex((_buff) => _buff.id === buffId);
		const removed = target.buffs.splice(idx, 1);

		return removed?.[0] ?? null;
	}

	applySkillBuffs({ targetId, skillId, duration, stacks }) {
		const target = this.getPlayerById(targetId);
		if (!target) return;

		const skill = getSkill(skillId);
		if (!skill) return;

		const buffIdsToApply = (Object.values(skill.applyBuffs) ?? []).map((_x) => _x.id);
		if (buffIdsToApply.length === 0) return;

		const presentBuffIds = target.buffs.map((_x) => _x.id);

		for (const buffId of buffIdsToApply) {
			const buff = getBuff(buffId); // id, duration, skillId

			let buffDuration = buff.duration ?? 0;
			let buffStacks = buff.stacks ?? 0;

			if (duration) buffDuration = duration;
			if (stacks) buffStacks = stacks;

			// Check if the buff is already applied
			if (presentBuffIds.includes(buffId)) {
				const found = target.buffs.find((_buff) => _buff.id === buffId);
				found.duration = buffDuration;
				found.stacks = buffStacks;
				continue;
			}

			target.buffs.push({
				id: buffId,
				duration: buffDuration,
				stacks: buffStacks,
			});

			// Start interval
			const interval = setInterval(() => {
				const found = target.buffs.find((_buff) => _buff.id === buffId);
				if (!found) return clearInterval(interval);

				found.duration -= 1;

				const buff = getBuff(buffId);

				// Heal
				if (buff.type === "healOverTime") {
					this.healPlayer({
						id: targetId,
						amount: buff.basePower,
					});
				}

				// Finish
				if (found.duration <= 0) {
					const idx = target.buffs.findIndex((_buff) => _buff.id === buffId);
					target.buffs.splice(idx, 1);

					clearInterval(interval);
				}
			}, 1000);
		}
	}

	useBuffStack({ targetId, buffId }) {
		const target = this.getPlayerById(targetId);
		if (!target) return console.warn("[useBuffStack] target not found");

		const buff = this.getBuffFromPlayer(targetId, buffId);
		if (!buff?.id) return console.warn("[useBuffStack] buff not found");

		// const skill = getSkill(buff.skillId);

		// Subtract stacks
		const buffIndex = target.buffs.findIndex((_buff) => _buff.id === buffId);
		if (buffIndex === -1) return console.warn("[useBuffStack] buff not found");

		const newStacks = target.buffs[buffIndex].stacks - 1;

		if (newStacks === 0) {
			this.removeBuff({
				targetId,
				buffId,
			});
		} else {
			target.buffs[buffIndex].stacks = newStacks;
		}
	}

	getPlayersSortedByHealth() {
		// Clone this.group to prevent mutation
		const unsorted = [...this.group];

		const sorted = unsorted.sort((a, b) => {
			if (a.stats.health > b.stats.health) return 1;
			if (a.stats.health < b.stats.health) return -1;
			return 0;
		});

		return sorted;
	}

	// reset() {
	// 	construct(this, defaultProperties);
	// };
}
