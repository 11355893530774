import { userStore } from "@/mobx/rootStore";
import dame from "dame";

export async function storeToken(token) {
	dame.headers.Authorization = `Bearer ${token}`;
	userStore.token = token;

	await userStore.init();
	// await staticDataStore.init();
	// await businessStore.fetchList();
}
