import { fetchMyCrews } from "@/utils/crew/fetchMyCrews";
import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";

// eslint-disable-next-line
let _rootStore;

const defaultProperties = {
	list: null,
	selected: null,
	lastFetch: null,
};

export default class CrewStore {
	constructor(rootStore) {
		construct(this, defaultProperties);
		_rootStore = rootStore;

		makeAutoObservable(this);
		persist(this, "crewStore");
	}

	reset() {
		construct(this, defaultProperties);
	}

	async fetchMyCrews(force = false) {
		// Prevent fetching more than once per second
		if (!force && this.lastFetch && Date.now() - this.lastFetch < 1000) {
			return;
		}

		this.lastFetch = Date.now();

		const crews = await fetchMyCrews();
		this.list = crews;

		if (!crews?.length) {
			this.selected = null; // unselect crew
		}

		if (!this.selected) {
			this.selected = crews?.[0]; // select first crew if no selection found
		} else {
			const found = this.list.find((_x) => _x.id === this.selected.id);
			if (found) {
				this.selected = found;
			} else {
				this.selected = null;
			}
		}
	}

	selectCrewById(id) {
		const foundCrew = this.list.find((_crew) => _crew.id === id);
		if (!foundCrew) return;

		this.selected = foundCrew;
	}

	/**
	 * @param {string} field
	 */
	getSelectedCrew(field) {
		if (!field) return this.selected;
		return this.selected?.[field];
	}
}
