import { userStore } from "@/mobx/rootStore";
import { routesList } from "@/routesList";
import { Button, Stack, Title } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

const APPS = [
	{
		label: "Mementos",
		appName: "mementos",
		route: routesList.mementos.path,
	},
	{
		label: "Eidos",
		appName: "eidos",
		route: routesList.eidos.path,
	},
];

export default function AppSelector({ closeMobile }) {
	const location = useLocation();
	const navigate = useNavigate();

	const currentApp = (location.pathname ?? "").split("/")[1]; // "/mementos/asd" -> "mementos"

	const onAppClick = (app) => {
		userStore.lastApp = app.appName;

		navigate(app.route);
		closeMobile();
	};

	return (
		<Stack gap="sm">
			<Title order={4}>Aplicaciones</Title>

			{APPS.map((_app) => {
				const isCurrentApp = currentApp === _app.appName;

				return (
					<Button
						key={_app.appName}
						variant={isCurrentApp ? "light" : "subtle"}
						onClick={isCurrentApp ? undefined : () => onAppClick(_app)}
					>
						{_app.label}
					</Button>
				);
			})}
		</Stack>
	);
}
