import { Group, Loader, Menu, Paper, Select, Text } from "@mantine/core";
import { IconUser, IconUserBolt } from "@tabler/icons-react";
import classes from "./CrewMember.module.css";
import { userStore } from "@/mobx/rootStore";
import { IconUserShield } from "@tabler/icons-react";

export default function CrewMember({
	crewMember = {},
	onChangeRole = () => {},
	isLoading = false,
}) {
	const myUsername = userStore?.data?.username;
	const memberRole = crewMember.role;

	let memberIcon = <IconUser />;

	if (memberRole === "owner") memberIcon = <IconUserBolt color="var(--mantine-color-yellow-4)" />;
	if (memberRole === "admin") memberIcon = <IconUserShield color="var(--mantine-color-cyan-4)" />;

	const itsMe = myUsername === crewMember?.username;

	return (
		<Menu shadow="md" width={200}>
			<Paper className={classes.target} withBorder p="xs">
				<Group justify="space-between">
					<Group>
						{memberIcon}
						<Text>
							{crewMember?.username}{" "}
							<Text span c="dimmed">
								{itsMe ? "(tú)" : ""}
							</Text>
						</Text>
					</Group>

					{isLoading && <Loader />}

					<Select
						label=""
						placeholder="Rol"
						value={memberRole}
						onChange={(value) => onChangeRole({ userId: crewMember?.id, value })}
						disabled={memberRole === "owner" || isLoading}
						data={[
							{ value: "owner", label: "Propietario", disabled: true },
							{ value: "admin", label: "Administrador" },
							{ value: "member", label: "Miembro" },
						]}
						allowDeselect={false}
						checkIconPosition="left"
						w={150}
					/>
				</Group>
			</Paper>
		</Menu>
	);
}
