
import { endpoints } from "@/utils/common/requests/endpoints";
import dame from "dame";



export async function fetchMyCrews () {
    const { isError, response } = await dame.get(endpoints.crew.GET_CURRENT());
    
    if (isError) return null;
    return response.crews;
};
