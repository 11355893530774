import { configure } from "mobx";
import UserStore from "./stores/userStore";
import UiStore from "./stores/uiStore"
import CrewStore from "./stores/crewStore";
import HealbotStore from "./stores/healbotStore";
// import StaticDataStore from "./stores/staticDataStore";



configure({
	enforceActions: "never",
});



class RootStore {
	
	estoEsRoot = true;
	
	constructor() {
		this.userStore = new UserStore(this);
		this.uiStore = new UiStore(this);
		this.crewStore = new CrewStore(this);
		// this.staticDataStore = new StaticDataStore(this);
		this.healbotStore = new HealbotStore(this);
	}
	
};

const rootStore = new RootStore();



export const userStore = rootStore.userStore;
export const uiStore = rootStore.uiStore;
export const crewStore = rootStore.crewStore;
// export const staticDataStore = rootStore.staticDataStore;
export const healbotStore = rootStore.healbotStore;


