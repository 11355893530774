import dame from "dame";
import { gatherRequestReturn } from "../hooks/api/_utils/gatherRequestReturn";

export const swrFetcher = async ([
	url,
	options = {
		method: "get",
	},
]) => {
	const resDame = await dame[options.method](url);
	const ret = gatherRequestReturn(resDame);

	if (ret.isError) throw new Error(ret.response.error);
	return ret.response; // error will be returned by useSWR, here we just need the response
};
