import { handleApiResponse } from "@/utils/common/api/handleApiResponse";
import { useValidateMagicLink } from "@/utils/hooks/api/auth/useValidateMagicLink";
import { Center, LoadingOverlay, Text } from "@mantine/core";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routesList } from "../../routesList";
import { storeToken } from "../../utils/auth/storeToken";

export default observer(function MagicLinkView() {
	const navigate = useNavigate();
	const [URLSearchParams] = useSearchParams();

	const code = URLSearchParams.get("code");

	const { trigger: validateCode, isMutating: isValidatingCode } = useValidateMagicLink();

	const [isError, setIsError] = useState(false);

	useEffect(() => {
		checkCode(code);
	}, []);

	const checkCode = async (code) => {
		if (!code) return;

		const apiResponse = await validateCode({
			query: {
				code,
			},
		});

		handleApiResponse({
			id: "login",
			apiResponse: apiResponse,
			successTitle: "Sesión iniciada con éxito",
			onSuccess: async () => {
				await storeToken(apiResponse.response.token);
				navigate(routesList.mementos.path);
			},
		});

		if (apiResponse.isError) {
			setIsError(true);
		}
	};

	return (
		<Center h="100vh">
			{isError && <Text>Código inválido</Text>}
			{isValidatingCode && <LoadingOverlay>Validando...</LoadingOverlay>}
		</Center>
	);
});
