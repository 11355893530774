import Logo from "@/components/common/Logo";
import { AppShell, Burger, Divider, Group, Stack, Text, rem } from "@mantine/core";
import { useDisclosure, useHeadroom } from "@mantine/hooks";
import { useDrag } from "@use-gesture/react";
import { version } from "../../../package.json";
import AppSelector from "./partials/AppSelector";
import CrewSelector from "./partials/CrewSelector";
import UserInfo from "./partials/UserInfo";

export default function PrivateLayout({ children }) {
	const [mobileOpened, { toggle: toggleMobile, close: closeMobile, open: openMobile }] =
		useDisclosure();
	const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
	const pinned = useHeadroom({ fixedAt: 120 });

	/*
		const bind2 = useDrag(
		({ movement, ...rest }) => {
			const [x] = movement;

			const node = rest?.event?.target;

			let swipeAction = node?.dataset?.swipeAction;
			let currentNode = node;

			// Get the nearest parent with the data-swipe-action attribute
			if (!swipeAction) {
				while (currentNode?.parentNode) {
					if (currentNode.parentNode?.dataset?.swipeAction) {
						swipeAction = currentNode.parentNode.dataset.swipeAction;
						break;
					}
					currentNode = currentNode?.parentNode;
				}
			}

			console.log(x);

			const isSwipeLeft = x < -30;
			const isSwipeRight = x > 30;

			if (isSwipeRight) {
				if (swipeAction === "openMenu") openMobile();
			}
			if (isSwipeLeft) {
				if (swipeAction === "closeMenu") closeMobile();
			}
		},
		{
			axis: "x",
			swipe: { distance: 30, duration: 300, trackMouse: true },
		},
	);
	*/

	const bind = useDrag(
		({ swipe, tap }) => {
			if (tap) {
				return;
			}

			const swipeX = swipe[0]; // position will either be -1, 0 or 1

			if (swipeX === 0) {
				return;
			}
			if (swipeX === -1) {
				closeMobile();
			}
			if (swipeX === 1) {
				openMobile();
			}
		},
		{
			filterTaps: true,
			axis: "x",
			swipe: {
				distance: 20,
				velocity: 1,
				duration: 1500,
			},
		},
	);

	return (
		<AppShell
			padding="md"
			header={{
				height: 60,
				collapsed: !mobileOpened && !pinned,
				offset: mobileOpened || desktopOpened,
			}}
			navbar={{
				width: 300,
				breakpoint: "sm",
				collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
			}}
			style={{
				touchAction: "pan-y",
			}}
			{...bind()}
			data-swipe-action="openMenu"
		>
			<AppShell.Header>
				<Group h="100%" px="md">
					<Burger
						opened={mobileOpened}
						onClick={toggleMobile}
						hiddenFrom="sm"
						size="sm"
					/>
					<Burger
						opened={desktopOpened}
						onClick={toggleDesktop}
						visibleFrom="sm"
						size="sm"
					/>

					<Group gap="sm">
						<Logo />
						<Text size="lg" fw="bold">
							Agora
						</Text>
					</Group>
					<Text size="sm" c="dimmed">
						{version}
					</Text>
				</Group>
			</AppShell.Header>

			<AppShell.Navbar p="md">
				<Stack gap="xl" justify="space-between" h={"100%"} data-swipe-action="closeMenu">
					<Stack>
						<CrewSelector />
						<Divider />
						<AppSelector closeMobile={closeMobile} />
					</Stack>

					<Stack>
						<Divider />
						<UserInfo />
					</Stack>
				</Stack>
			</AppShell.Navbar>

			<AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
				{children}
			</AppShell.Main>
		</AppShell>
	);
}
