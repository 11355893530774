import { crewStore } from "@/mobx/rootStore";
import { endpoints } from "@/utils/common/requests/endpoints";
import { getUseSwrReturn } from "@/utils/swr/getUseSwrReturn";
import useSWR from "swr";

/**
 *
 * @returns {import("swr").SWRResponse}
 */
export default function useGetCrewMembers() {
	const res = useSWR([endpoints.crew.GET_MEMBERS(crewStore.getSelectedCrew("id"))]);
	return getUseSwrReturn(res);
}
