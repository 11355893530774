import dame from "dame";



export async function fetchMe () {
	
    const {isError, response} = await dame.get("/auth/me");
    
    return {
        success: !isError,
        me: response
    };
};
