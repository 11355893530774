import { userStore } from '@/mobx/rootStore';
import { logout } from '@/utils/auth/logout';
import { Button, Group, Stack, Text } from '@mantine/core';
import { IconLogout, IconUserCircle } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';



export default observer(function UserInfo() {
	
	return (

		<Stack gap="sm">
			
			<Group ml="xs" gap="xs">
				<IconUserCircle />
				<Text
					size="lg"
				>
					<Text span c="dimmed">
						Usuario:&nbsp;				
					</Text>
					{userStore?.data?.username}
				</Text>
			</Group>
			
			<Button
				variant='subtle'
				leftSection={<IconLogout />}
				justify='flex-start'
				onClick={() => {
					logout({});
				}}
			>
				<Text>Salir</Text>
			</Button>
			
		</Stack>

	);

});


