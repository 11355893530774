import { endpoints } from "@/utils/common/requests/endpoints";
import {
	Button,
	CopyButton,
	Flex,
	Loader,
	Modal,
	Stack,
	Text,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { IconHelpHexagonFilled } from "@tabler/icons-react";
import dame from "dame";
import { useState } from "react";
import CrewMember from "./partials/CrewMember/CrewMember";
import useGetCrewMembers from "./requests/useGetCrewMembers";
import { useUpdateCrewMemberRole } from "./requests/useUpdateCrewMemberRole";
import { handleApiResponse } from "@/utils/common/api/handleApiResponse";

export default function CrewMembers({
	opened = true,
	onClose = () => {},
	crew = {},
	onRenewInvitationId = () => {},
}) {
	const { data: crewMembersData, error, isLoading, mutate } = useGetCrewMembers();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isMutatingUser, setIsMutatingUser] = useState(null);

	const { trigger: updateCrewMemberRole } = useUpdateCrewMemberRole({
		crewId: crew?.id,
	});

	const invitationLink = `${import.meta.env.VITE_APP_URL}/crew-invitation?id=${crew?.invitationId}`;

	const handleRenew = async () => {
		setIsSubmitting(true);
		const { response } = await dame.patch(endpoints.crew.RENEW_INVITATION(crew?.id), {});

		if (response.invitationId) {
			onRenewInvitationId(response.invitationId);
			onClose();
		}

		setIsSubmitting(false);
	};

	async function handleChangeRole({ userId, role }) {
		try {
			setIsMutatingUser(userId);

			const apiResponse = await updateCrewMemberRole({
				payload: {
					userId,
					role,
				},
			});

			handleApiResponse({
				id: "register",
				apiResponse: apiResponse,
				successTitle: "Rol cambiado con éxito",
				onSuccess: () => {
					mutate();
				},
			});
		} catch (err) {
		} finally {
			setIsMutatingUser(null);
		}
	}

	if (isLoading) return <Loader />;
	if (error) return <h1>Error</h1>;

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={
				<Text span size="xl" fw="bold">
					Gestionar miembros del grupo
				</Text>
			}
		>
			<Stack gap="xl">
				<Stack>
					<Text size="sm" fw={500}>
						Enlace de invitación
					</Text>

					<Flex gap="md" align="center">
						<TextInput
							value={invitationLink}
							style={{
								flex: 1,
							}}
							readOnly
							onFocus={(ev) => {
								// Select all text
								ev.target.select();
							}}
						/>
						<CopyButton value={invitationLink}>
							{({ copied, copy }) => (
								<Button
									color={copied ? "teal" : "blue"}
									onClick={copy}
									loading={isSubmitting}
								>
									{copied ? "¡Copiado!" : "Copiar"}
								</Button>
							)}
						</CopyButton>
					</Flex>

					<Flex gap="md" align="center">
						<Button
							variant="light"
							style={{ flex: 1 }}
							onClick={handleRenew}
							loading={isSubmitting}
						>
							Renovar enlace de invitación
						</Button>

						<Tooltip
							events={{ hover: true, touch: true }}
							label="Esta acción invalidará el enlace de invitación actual y creará uno nuevo. Todas las invitaciones enviadas y no aceptadas, dejarán de funcionar."
							multiline
							withArrow
							w={300}
						>
							<IconHelpHexagonFilled />
						</Tooltip>
					</Flex>
				</Stack>

				<Stack>
					<Text size="sm" fw={500}>
						Miembros
					</Text>

					<Stack>
						{(crewMembersData?.members ?? []).map((_member) => {
							return (
								<CrewMember
									key={_member.id}
									crewMember={_member}
									onChangeRole={({ userId, value }) =>
										handleChangeRole({ userId, role: value })
									}
									isLoading={isMutatingUser === _member.id}
								/>
							);
						})}
					</Stack>
				</Stack>
			</Stack>
		</Modal>
	);
}
