import { crewStore } from "@/mobx/rootStore";
import { Button, Divider, Group, Modal, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import {
	IconCheck,
	IconPlus,
	IconTag,
	IconTrash,
	IconUsersGroup,
	IconX,
} from "@tabler/icons-react";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import CrewMembers from "../CrewMembers/CrewMembers";

export default function CrewFields({
	opened = true,
	onClose = () => {},
	onSubmit = () => {},
	crew = {},
	loading = false,
	showDelete = true,
	onDelete = () => {},
}) {
	const [isEditingMembers, setIsEditingMembers] = useState(false);

	const isEditing = Boolean(crew?.id);

	const polymorphicData = {
		modalTitle: "Crear grupo",
		submitIcon: <IconPlus />,
		submitText: "Crear",
	};

	if (isEditing) {
		polymorphicData.modalTitle = "Editar grupo";
		polymorphicData.submitIcon = <IconCheck />;
		polymorphicData.submitText = "Guardar";
	}

	const form = useForm({
		initialValues: {
			name: "",
		},
		validate: {
			name: (value) => (value.length === 0 ? "Este campo es obligatorio" : null),
		},
	});

	useEffect(() => {
		if (!crew) return;
		if (crew.id) {
			form.setValues(toJS(crew));
		} else {
			form.reset();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crew?.id]);

	const handleConfirmDelete = (crew) => {
		modals.openConfirmModal({
			title: "Eliminar crew",
			centered: true,
			children: (
				<Text size="sm" span>
					Esta acción eliminará &quot;{crew.name}&quot; de forma permanente y no se podrá
					recuperar la información.
				</Text>
			),
			labels: {
				confirm: "Eliminar crew",
				cancel: "Cancelar",
			},
			confirmProps: {
				color: "red",
				leftSection: <IconTrash />,
			},
			onConfirm: () => onDelete(crew),
		});
	};

	const handleRenewInvitationId = () => {
		crewStore.fetchMyCrews();
		onClose();
	};

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={
				<Text span size="xl" fw="bold">
					{polymorphicData.modalTitle}
				</Text>
			}
		>
			<form
				onSubmit={form.onSubmit((values) => {
					onSubmit(values);
				})}
			>
				<Stack>
					{crew?.id && (
						<Text size="xs" c="dimmed" opacity={0.33}>
							id {crew?.id}
						</Text>
					)}

					<TextInput
						label="Nombre"
						icon={<IconTag />}
						placeholder="Nombre del grupo"
						{...form.getInputProps("name")}
					/>

					{isEditing && (
						<>
							<Divider my="sm" />

							<Button
								variant="light"
								leftSection={<IconUsersGroup />}
								onClick={() => setIsEditingMembers(true)}
							>
								Gestionar miembros
							</Button>

							{showDelete && (
								<Button
									leftSection={<IconTrash />}
									color="red"
									variant="light"
									onClick={() => handleConfirmDelete(crew)}
								>
									Eliminar grupo
								</Button>
							)}

							<Divider my="sm" />
						</>
					)}

					<Group position="apart" grow>
						<Button
							variant="default"
							color="red"
							leftSection={<IconX />}
							onClick={onClose}
						>
							Cancelar
						</Button>
						<Button
							leftSection={polymorphicData.submitIcon}
							type="submit"
							loading={loading}
						>
							{polymorphicData.submitText}
						</Button>
					</Group>
				</Stack>
			</form>

			<CrewMembers
				opened={isEditingMembers}
				onClose={() => setIsEditingMembers(false)}
				crew={crew}
				onRenewInvitationId={handleRenewInvitationId}
			/>
		</Modal>
	);
}
