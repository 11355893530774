import { uiStore, userStore } from "@/mobx/rootStore";
import dame from "dame";

export async function logout({ redirect = "/login" }) {
	console.log(redirect);

	dame.headers.Authorization = "";
	userStore.reset();
	uiStore.reset();

	if (redirect) {
		window.location.href = redirect;
	}
}
