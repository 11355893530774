import { healbotStore } from "@/mobx/rootStore";

export function getSkill(id) {
	const skill = skills[id];
	return skill;
}

export function getBuff(id) {
	const buff = buffs[id];
	return buff;
}

function getMultipliersText(skill) {
	const multipliers = skill.multipliers ?? {};

	const activeMultipliers = [];

	if (multipliers.strength) activeMultipliers.push(`STR x${multipliers.strength}`);
	if (multipliers.agility) activeMultipliers.push(`AGI x${multipliers.agility}`);
	if (multipliers.intelligence)
		activeMultipliers.push(`INT x${multipliers.intelligence}`);
	if (multipliers.faith) activeMultipliers.push(`FAI x${multipliers.faith}`);

	if (activeMultipliers.length === 0) {
		return ["Esta habilidad no escala"];
	}

	const finalArr = ["Esta habilidad escala con:", ...activeMultipliers];
	return finalArr;
}

/**
 * @typedef  {Object} Skill
 * @property {string} id
 * @property {"heal", "damage"} type
 * @property {Array<"friendly" | "enemy">} targets
 * @property {string} name
 * @property {Array<{id: string}>} buffsToApply
 * @property {{description: string, multipliers: string[]}} getDescription
 * @property {number} cooldown
 * @property {number} castTime
 * @property {number} manaCost
 * @property {number} basePower
 * @property {Object} multipliers
 * @property {string} icon
 */

/**
 * @type {Skill[]}
 */
export const skills = {
	HOLY_TOUCH: {
		id: "HOLY_TOUCH",
		type: "heal",
		targets: ["friendly"],
		name: "Toque sagrado",
		getDescription: function () {
			const description = `Cura ${this.basePower} a un aliado.`;
			const multipliers = getMultipliersText(this);
			return {
				description,
				multipliers,
			};
		},
		cooldown: 3,
		castTime: 1.5,
		manaCost: 10,
		basePower: 250,
		multipliers: {
			strength: 0,
			agility: 0,
			intelligence: 0.5,
			faith: 1,
		},
		icon: "/img/healbot/skills/HOLY_TOUCH.jpg",
	},
	HOLY_HAND: {
		id: "HOLY_HAND",
		type: "heal",
		targets: ["friendly"],
		name: "Mano sagrada",
		getDescription: function () {
			const description = `Cura ${this.basePower} a un aliado.`;
			const multipliers = getMultipliersText(this);
			return {
				description,
				multipliers,
			};
		},
		cooldown: 3,
		castTime: 3,
		manaCost: 10,
		basePower: 600,
		multipliers: {
			strength: 0,
			agility: 0,
			intelligence: 1,
			faith: 1.5,
		},
		icon: "/img/healbot/skills/HOLY_HAND.jpg",
	},
	RENEW: {
		id: "RENEW",
		type: "heal",
		targets: ["friendly"],
		name: "Renovación",
		applyBuffs: [
			{
				id: "BUFF:RENEW",
			},
		],
		getDescription: function () {
			const buff = getBuff(this.applyBuffs[0].id);

			const description = `Cura ${buff.basePower} a un aliado cada segundo durante ${buff.duration} segundos.`;
			const multipliers = getMultipliersText(this);
			return {
				description,
				multipliers,
			};
		},
		cooldown: 4,
		castTime: 0,
		manaCost: 5,
		basePower: 0,
		multipliers: {
			strength: 0,
			agility: 0,
			intelligence: 1,
			faith: 1.5,
		},
		icon: "/img/healbot/skills/RENEW.jpg",
	},
	RELIEF: {
		id: "RELIEF",
		type: "heal",
		targets: ["friendly"],
		name: "Alivio",
		applyBuffs: [
			{
				id: "BUFF:RELIEF",
			},
		],
		getDescription: function () {
			const buff = getBuff(this.applyBuffs[0].id);

			const description = `Cura ${buff.basePower} a un aliado cuando recibe daño y se consume una acumulación de ${this.name} pero salta al aliado con menos salud. Dura ${buff.duration} segundos y tiene ${buff.stacks} acumulaciones.`;
			const multipliers = getMultipliersText(this);
			return {
				description,
				multipliers,
			};
		},
		cooldown: 15,
		castTime: 0,
		manaCost: 10,
		basePower: 0,
		multipliers: {
			strength: 0,
			agility: 0,
			intelligence: 1,
			faith: 1.5,
		},
		icon: "/img/healbot/skills/RELIEF.jpg",
	},
};

export const buffs = {
	"BUFF:RENEW": {
		id: "BUFF:RENEW",
		type: "healOverTime",
		basePower: 25,
		duration: 20,
		skillId: "RENEW",
	},
	"BUFF:RELIEF": {
		id: "BUFF:RELIEF",
		type: "protection",
		basePower: 175,
		duration: 30,
		stacks: 5,
		skillId: "RELIEF",
		onReceivedDamage: function (event) {
			const { target } = event;

			setTimeout(() => {
				healbotStore.healPlayer({ id: target.id, amount: this.basePower });
				healbotStore.useBuffStack({ targetId: target.id, buffId: this.id });

				const removedBuff = healbotStore.removeBuff({
					targetId: target.id,
					buffId: this.id,
				});
				if (!removedBuff)
					return console.warn("[onReceivedDamage] buff not found");

				const buff = getBuff(removedBuff.id);
				const skill = getSkill(buff.skillId);

				const sortedPlayers = healbotStore.getPlayersSortedByHealth();
				const mostDamagedPlayer = sortedPlayers[0];

				healbotStore.applySkillBuffs({
					targetId: mostDamagedPlayer.id,
					skillId: skill.id,
					duration: removedBuff.duration,
					stacks: removedBuff.stacks,
				});
			}, 500);
		},
	},
};
