import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { version } from "../package.json";


// PRO
if (import.meta.env.VITE_ENV === "pro") {
	const bugSnagApikey = import.meta.env.VITE_BUGSNAG_APIKEY;

	Bugsnag.start({
		apiKey: bugSnagApikey,
		plugins: [new BugsnagPluginReact()],
		appVersion: version,
	});
	BugsnagPerformance.start({ apiKey: bugSnagApikey });

	const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

	ReactDOM.createRoot(document.getElementById("root")).render(
		<ErrorBoundary>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ErrorBoundary>,
	);

} else {
	// LOCAL
	ReactDOM.createRoot(document.getElementById("root")).render(

		<React.StrictMode>
			<App />
		</React.StrictMode>

	);
}


