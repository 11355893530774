/**
 * @returns {import("swr").SWRResponse}
 */
export function getUseSwrReturn(ret) {
	return {
		data: ret.data ?? {},
		isError: ret.isError,
		isLoading: ret.isLoading,
		mutate: ret.mutate,
	};
}
