export const routesList = {
	home: {
		path: "/",
		name: "Inicio",
	},

	register: {
		path: "/register",
		name: "Registro",
	},
	login: {
		path: "/login",
		name: "Iniciar sesión",
	},
	magicLink: {
		path: "/magicLink",
		name: "Magic link",
	},

	crewInvitation: {
		path: "/crew-invitation",
		name: "Invitación a grupo",
	},

	// Apps
	eidos: {
		path: "/eidos",
		name: "Eidos",
	},
	mementos: {
		path: "/mementos",
		name: "Mementos",
	},
	healbot: {
		path: "/healbot",
		name: "Heal bot",
	},
};
