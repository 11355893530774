import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";


const defaultProperties = {
	language: "es-ES",
	theme: "dark",
	header: {
		height: 70,
	},
	navbar: {
		width: 0,
	},
};

export default class UiStore {
	
	constructor() {
		construct(this, defaultProperties);
		
		makeAutoObservable(this);
		persist(this, "uiStore");
	};
	
	reset() {
		construct(this, defaultProperties);
	};
	
	
	/** * @param {"light" | "dark"} theme */
	toggleTheme = (theme) => {
		
		// Forzamos tema
		if (theme) {
			
			if (!["light", "dark"].includes(theme)) {
				return console.warn( `[setTheme] ${theme} is not a valid theme` )
			};
			
			this.theme = theme;
			
		// Cambiamos de un tema al otro
		} else {
			if (this.theme === "light") {
				this.theme = "dark";
			} else {
				this.theme = "light";
			};
		};
		
	};
	
	/**
	 * Devuelve si estamos en tema oscuro o no.
	 * @returns {boolean} true si estamos en tema oscuro, false si no.
	*/
	isDarkTheme = () => this.theme === "dark";
	
	
}
