import { logout } from "@/utils/auth/logout";
import { fetchMe } from "@/utils/auth/me";
import { makeAutoObservable } from "mobx";
import construct from "../utils/construct";
import persist from "../utils/persist";
import Bugsnag from "@bugsnag/js";

// eslint-disable-next-line
let _rootStore;

/**
 * @typedef {Object} UserStoreProps
 * @property {string} token The authentication token for the user
 * @property {{}} data The user data fetched from the server
 * @property {"mementos" | "eidos" | null} lastApp The last application the user interacted with
 */

/** @type {UserStoreProps} */
const defaultProperties = {
	token: null,
	data: null,
	lastApp: null,
};

export default class UserStore {
	token = defaultProperties.token;
	data = defaultProperties.data;
	lastApp = defaultProperties.lastApp;

	constructor(rootStore) {
		construct(this, defaultProperties);
		_rootStore = rootStore;

		makeAutoObservable(this);
		persist(this, "userStore");
	}

	reset() {
		construct(this, defaultProperties);
	}

	async fetchData() {
		const { success, me } = await fetchMe();

		if (!success) {
			if (import.meta.env.VITE_ENV === "pro") {
				return logout();
			}
		}

		if (!success) {
			return console.log("[UserStore.fetchData] error");
		}

		if (import.meta.env.VITE_ENV === "pro") {
			Bugsnag.setUser(me?.id, me?.email, me?.username);
		}

		this.data = me;
	}

	async init() {
		console.log("[agora] INIT: Iniciando...");

		if (this.token) {
			this.fetchData();
			// staticDataStore.fetchData();

			console.log("[agora] INIT: ok");
		} else {
			console.log("[agora] INIT: no token");
			logout();
		}
	}
}
