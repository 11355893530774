import CrewFields from "@/components/Crew/CrewFields/CrewFields";
import { crewStore } from "@/mobx/rootStore";
import { endpoints } from "@/utils/common/requests/endpoints";
import { ActionIcon, Button, Combobox, Group, Input, InputBase, Stack, Title, useCombobox } from "@mantine/core";
import { IconPencil, IconPlus } from "@tabler/icons-react";
import dame from "dame";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';



export default observer(function CrewSelector() {

	const [editingCrew, setEditingCrew] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const combobox = useCombobox({
		onDropdownClose: () => combobox.resetSelectedOption(),
	});

	useEffect(() => {
		crewStore.fetchMyCrews();
	}, []);
	
	
	const handleSubmitCrew = async (crew) => {

		setSubmitting(true);

		
		if (crew.id) {
			await dame.patch(
				endpoints.crew.UPDATE_NAME(crew.id),
				crew
			)
		} else {
				
			crew.kind = "mementos";
			
			await dame.post(
				endpoints.crew.CREATE(),
				crew
			)

		};

		setSubmitting(false);
		crewStore.fetchMyCrews();
		setEditingCrew(null);

	};


	const crewList = crewStore.list ?? [];
	const hasCrews = Boolean(crewList.length);
	
	
	
	// Selector option
	const selectedCrew = crewList.find(_crew => _crew.id === crewStore.getSelectedCrew("id"));
	
	
	return (

		<Stack gap="sm">
			<Group justify="space-between">
				<Title order={4}>Grupo</Title>
				<ActionIcon
					variant="subtle"
					onClick={() => setEditingCrew({id: null})}
				>
					<IconPlus />
				</ActionIcon>
			</Group>

			{!hasCrews &&
				<Button
					leftSection={<IconPlus />}
					onClick={() => setEditingCrew({id: null})}
				>
					Crear grupo
				</Button>
			}

			{hasCrews &&
				<Group
					wrap="nowrap"
					justify="space-between"
				>
					<Combobox
						store={combobox}
						withinPortal={false}
						onOptionSubmit={_crewId => {
							crewStore.selectCrewById(_crewId);
							combobox.closeDropdown();
						}}
					>
						<Combobox.Target>
							<InputBase
								w={"100%"}
								component="button"
								pointer
								rightSection={<Combobox.Chevron />}
								onClick={() => combobox.toggleDropdown()}
								rightSectionPointerEvents="none"
								multiline
							>
								{selectedCrew
									? selectedCrew?.name
									: <Input.Placeholder>ERR</Input.Placeholder>
								}
							</InputBase>
						</Combobox.Target>

						<Combobox.Dropdown>
							<Combobox.Options>
								{crewList.map((item) => {
									return <Combobox.Option value={item.id} key={item.id}>
										{item.name}
									</Combobox.Option>
								})}
							</Combobox.Options>
						</Combobox.Dropdown>
					</Combobox>
					
					<ActionIcon
						style={{
							flexGrow: 0
						}}
						variant="subtle"
						onClick={() => setEditingCrew(crewStore.selected)}
					>
						<IconPencil />
					</ActionIcon>
					
				</Group>
			}

			{/* ********************************************* */}
			{/* Modals */}
			{/* ********************************************* */}

			

			<CrewFields
				opened={Boolean(editingCrew)}
				crew={editingCrew}
				onClose={() => setEditingCrew(null)}
				onSubmit={crew => handleSubmitCrew(crew)}
				loading={submitting}
			/>
		</Stack>

	);

});


