import { endpoints } from "@/utils/common/requests/endpoints";
import { swrMutator } from "@/utils/swr/mutator";
import useSWRMutation from "swr/mutation";

export function useUpdateCrewMemberRole({ crewId }) {
	const { trigger, isMutating, data, error } = useSWRMutation(
		endpoints.crew.UPDATE_MEMBER_ROLE(crewId),
		(url, options) => {
			options.arg.method = "patch";
			return swrMutator(url, options);
		},
	);

	return {
		trigger,
		isMutating,
		data,
		error,
	};
}
